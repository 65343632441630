footer {
    padding: 40px 0;
    background-color: $black;

    @media screen and (min-width: 992px) {
        padding: 60px 0;
    }

    p {
        color: $white;
        font-size: 14px;
        line-height: 1.87;
        letter-spacing: 0.5px;
    }

    .tlo {
        font-family: $font-family-archivo;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
    }
}