@import '../variables';

.contacto {
    &-informacion {
        padding: 40px 0;
        background-color: $white;

        @media screen and (min-width: 992px) {
            padding: 60px 0;
        }
    }

    &-formularios {
        input, textarea {
            border: 1px solid $white !important;
            background-color: transparent !important;
            box-shadow: none!important;
            border-radius: 6px;
            color: $white !important;

            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;

            &::placeholder {
                color: $white;
            }
        }

        textarea {
            resize: none;
        }

        select {
            border: 1px solid $white !important;
            background-color: transparent !important;
            box-shadow: none!important;
            border-radius: 6px;
            color: $white !important;
        }

        .contacto-form {
            padding: 50px 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @media screen and (min-width: 992px) {
                padding: 70px 0;
            }
        }
    }
}