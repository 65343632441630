header {
    background-color: $white;
    padding: 25px 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;

    @media screen and (min-width: 992px) {
        position: relative;
    };

    &::after {
        content: "";
        width: 100%;
        height: 8px;
        background-color: $pale-grey;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .container-fluid {
        max-width: 1500px;
    }

    .logo img {
        max-width: 180px;

        @media screen and (min-width: 768px) {
            max-width: 274px;
        }
        @media screen and (min-width: 992px) {
            max-width: 100%;
        }
    }

    .menu {
        height: 25px;
        width: 35px;
        position: relative;
        cursor: pointer;

        &.menu-2 {
            &:after {
                content: '';
                display: block;
                bottom: 0;
                left: 0;
                width: 100%;
                background: $dark-indigo;
                height: 3px;
                position: absolute;
                transform: translate3d(0, 0, 0);
                opacity: 1;
                transition: transform 0.4s 0.4s, opacity 0.4s 0.4s;
            }

            &:before {
                content: '';
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                background: $dark-indigo;
                height: 3px;
                position: absolute;
                transform: translate3d(0, 0, 0);
                opacity: 1;
                transition: transform 0.4s 0.4s, opacity 0.4s 0.4s;
            }

            span {
                height: 3px;
                display: block;
                opacity: 1;
                transform: translate3d(0, 11px, 0);
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: $dark-indigo;
                    height: 3px;
                    position: absolute;
                    transform: rotate(0);
                    transition: transform 0.4s;
                }

                &:before {
                    content: '';
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: $dark-indigo;
                    height: 3px;
                    position: absolute;
                    transform: rotate(0);
                    transition: transform 0.4s;
                }
            }

            &.active {
                &:after {
                    transform: translate3d(0, -11px, 0);
                    opacity: 0;
                    transition: transform 0.4s, opacity 0.2s 0.2s;
                }

                &:before {
                    transform: translate3d(0, 11px, 0);
                    opacity: 0;
                    transition: transform 0.4s, opacity 0.2s 0.2s;
                }

                span {
                    &:after {
                        transform: rotate(45deg);
                        transition: transform 0.4s 0.4s;
                    }

                    &:before {
                        transform: rotate(-45deg);
                        transition: transform 0.4s 0.4s;
                    }
                }
            }	
        }
    }

    ul {
        li {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: $dark-two;

            @media screen and (min-width: 1200px) {
                font-size: 16px;
            }

            &:not(.idiomas){
                a {
                    position: relative;

                    &.active {
                        font-family: $font-family-archivo;

                        &::after {
                            content: "";
                            width: 19px;
                            height: 2px;
                            background-color: $dark-indigo;
                            position: absolute;
                            bottom: -8px;
                            left: 0;
                        }
                    }
                }
            }

        }
    }

    .sub-menu {
        background-color: $white;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;

        &::after {
            content: "";
            width: 100%;
            height: 8px;
            background-color: $pale-grey;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .border-r {
            border-right: 8px solid $pale-grey;
        }

        ul li {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark;
            opacity: .4;
            position: relative;

            &.active {
                color: $dark-indigo;
                opacity: 1;
                font-family: $font-family-archivo;

                &::after {
                    content: "";
                    width: 19px;
                    height: 2px;
                    background-color: $dark-indigo;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                }
            }
        }

        .list-sub-menu {
            li {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $dark;
                opacity: 1;
            }
        }
    }

    .sidebarMenu {
        position: fixed;
        top: 83px;
        left: 0;
        width: 100%;
        max-width: 380px;
        height: calc(100% - 83px);
        background: $white;
        z-index: -1;
        padding: 40px 30px;
        border-right: 8px solid $pale-grey;
        overflow: auto;
        transform: translateX(-100%);
        transition: all .3s ease-in-out;

        @media screen and (min-width: 768px) {
            top: 101px;
            height: calc(100% - 101px);
        }

        &.active {
            transform: translateX(0%);
        }

        ul li {
            transform: translateX(-10px);
            opacity: 0;
        }
    }
}