.max-width-100 {
    max-width: 100%;
}

.w12 {
    max-width: 1200px;
}
.w14 {
    max-width: 1400px;
}
.w15 {
    max-width: 1500px;
}
.w16 {
    max-width: 1600px;
}