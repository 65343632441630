@import '../variables';

.noticias {
    padding: 60px 0;

    @media screen and (min-width: 992px) {
        padding: 80px 0;
    }

    &-detalle {
        .titulo {
            color: $dark-indigo
        }

        .informacion {
            .portada {
                height: 350px;

                @media screen and (min-width: 768px) {
                    height: 450px;
                }
            }

            img {
                width: 100%;
            }

            p {
                line-height: 1.87;
                letter-spacing: 0.5px;
                color: $greyish-brown;
                font-size: 15px;
            }
        }

        .extras {
            .subtlo {
                font-family: $font-family-archivo;
                font-size: 20px;
                line-height: 1.4;
                letter-spacing: 0.67px;
                color: $dark-indigo;
            }

            hr {
                border-color: $dark-indigo;
            }

            ul {
                li + li {
                    margin-top: 14px;
                }
            }
        }
    }
}