@import '../variables';

.card-loading {
    max-width: 270px;
    border: none;
    border-radius: 0px;
    background-color: $pale-grey;
}

.nomax {
    .card-loading {
        max-width: none;
    }
}

.placeholder {
    &-img {
        height: 280px;
        width: 100%;
        background-color: rgb(219, 219, 219);
    }
    &-titulo,
    &-precio,
    &-link {
        margin-left: auto;
        margin-right: auto;
    }

    &-titulo {
        height: 20px;
        width: 80%;
        background-color: #dadde6;
    }

    &-precio {
        height: 20px;
        width: 50%;
        background-color: #dadde6;
    }

    &-link {
        height: 20px;
        width: 70%;
        background-color: #abb6db;
    }

    &-linkMenu {
        height: 20px;
        width: 70%;
        background-color: #abb6db;
        opacity: 1 !important;
    }

    &-linkSubMenu {
        height: 10px;
        width: 70%;
        max-width: 150px;
        background-color: #abb6db;
        opacity: 1 !important;
    }

    &-text {
        background-color: #dddddd;
        opacity: 1 !important;
    }
}