.nuestras-empresas {
    padding: 30px 0;
    background-color: $white;

    @media screen and (min-width: 992px) {
        padding: 60px 0;
    }

    .cont-img {
        width: 100%;
        height: 115px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}