@import '../variables';
@import '../imports/card_producto';
@import '~swiper/swiper.scss';
@import '~swiper/components/navigation/navigation.scss';
@import '~swiper/components/thumbs/thumbs.scss';

.productos {
    &-listado {
        padding: 60px 0;
        background-color: $white;

        hr {
            width: 80px;
            height: 1px;
            margin: 13px 354px 44px 1px;
            background-color: $dark-indigo;
            border: solid 2px $dark-indigo;
        }

        @media screen and (min-width: 992px) {
            padding: 80px 0;
        }
    }

    &-detalle {
        padding: 60px 0;
        background-color: $pale-grey;

        .after-white {
            position: relative;

            &::after {
                content: "";
                width: 60%;
                height: calc(100% + 60px);
                background-color: $white;
                z-index: 1;
                position: absolute;
                top: -30px;
                left: -20%;
            }
        }

        .ssm {
            height: 400px;
            object-fit: contain;
            border: 2px solid $pale-grey;
            border-radius: 3px;

            @media screen and (min-width: 992px) {
                height: 500px;
            }
        }

        .sst {
            background-color: $pale-grey;
            border: 2px solid $pale-grey;
            border-radius: 3px;

            &.swiper-slide-thumb-active {
                background-color: $white;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            transform: scale(.7);

            &::after {
                color: $dark-indigo;
            }
        }

        h4 {
            font-size: 25px;
            font-weight: 600;
            color: $black;
        }

        h3 {
            font-family: $font-family-archivo;
            font-size: 30px;
            color: $black;
        }

        p {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: 0.53px;
        }
    }

    &-similares {
        padding: 50px 0;

        @media screen and (min-width: 992px) {
            padding: 70px 0;
        }
    }
}