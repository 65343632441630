/*-------------------------------------------------
Tipografia
-------------------------------------------------*/
$font-family-hind: 'Hind', sans-serif;
$font-family-archivo: 'Archivo Black', sans-serif;

/*-------------------------------------------------
Colores
-------------------------------------------------*/
$white: #fff;
$greyish-brown: #434343;
$pale-grey: #f6f7f9;
$dark-indigo: #0f2a54;
$black: #000;
$dark: #212b36;
$dark-two: #151828;