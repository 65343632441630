@import '../variables';
@import '../imports/banner_blue';

.somos {
    &-section-1 {
        padding-top: 100px;
        padding-bottom: 30px;

        @media screen and (min-width: 992px) {
            padding-top: 160px;
            padding-bottom: 60px;
        }

        .img-object {
            width: 100%;
            height: 300px;

            @media screen and (min-width: 992px) {
                height: 400px;
            }
        }

        .square {
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                width: 100%;
                height: 80%;
                background-color: $pale-grey;
                position: absolute;
                top: -40px;
                right: 0px;
                z-index: -1;

                @media screen and (min-width: 768px) {
                    width: 80%;
                    height: 100%;
                    top: -60px;
                    right: -40px;
                }
            }
        }
    }

    &-section-2 {
        padding-top: 40px;
        padding-bottom: 60px;

        @media screen and (min-width: 992px) {
            padding-bottom: 100px;
        }

        &.pt-100 {
            padding-top: 60px;

            @media screen and (min-width: 992px) {
                padding-top: 100px;
            }
        }

        .card-nuestros-servicios {
            max-width: 410px;
            margin: auto;
            background-color: $pale-grey;
            border: none;
            border-radius: 0px;
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 35px;
                background-color: $white;
                position: absolute;
                top: 0;
                left: 0;
            }

            img, .card-body {
                position: relative;
                z-index: 1;
            }

            .titulo {
                @media screen and (min-width: 992px) {
                    font-size: 20px;
                }
            }
        }
    }

    &-section-3 {
        background-color: $dark-indigo;

        h5 {
            font-weight: 600;
        }

        p {
            font-size: 15px;
        }
    }

    &-section-4 {
        padding-top: 60px;
        padding-bottom: 60px;

        @media screen and (min-width: 992px) {
            padding-bottom: 80px;
        }

        .bg-gris {
            background-color: $pale-grey;
        }
    }
}