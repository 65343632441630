.banner-blue {
    width: 100%;
    height: 450px;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    p {
        line-height: 1.87;
        letter-spacing: 0.5px;
    }

    &.banner-small {
        min-height: 240px;
        height: auto;
    }
}