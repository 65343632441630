$chbx_size: 18px;
$bg-chbx: #ffffff;
$border-color: #e4edf5;
$border-width: 2px;
$bg-chbx-checked: #164278;

.custom-checkbox {
    display: none;

    + {
        label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.69px;
            color: $greyish-brown;

            span {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                width: $chbx_size;
                height: $chbx_size;
                cursor: pointer;
                position: relative;
                top: -2px;
                border: $border-width solid $border-color;
				background-color: $bg-chbx;
				border-radius: 3px;
				font-weight: 500;

                &::before {
                    content: "";
                    opacity: 0;
                    width: $chbx_size / 2;
                    height: $chbx_size / 2;
                    background-color: $bg-chbx-checked;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    bottom: auto;
                    right: auto;
                    transform: translate(-50%, -50%);
					transition: opacity 0.2s;
					border-radius: 2px;
                }
            }
        }
    }

    &:checked {
        + {
            label {
                span {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &:disabled {
        + {
            label {
                opacity: 0.5;
                cursor: default;

                span {
                    cursor: default;
                }
            }
        }
    }
}
