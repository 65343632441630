body {
    min-height: 100vh;
    background-color: $white;
    position: relative;
    font-family: $font-family-hind;
    padding-top: 83px;

    @media screen and (min-width: 768px) {
        padding-top: 101px;
    }
    @media screen and (min-width: 992px) {
        padding-top: 0;
    }

    * {
        outline: none !important;
    }

    a:hover,
    a:active,
    a:focus,
    a {
        color: inherit;
        text-decoration: none;
    }

    .bg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
        width: 100%;

        img {
            opacity: 0;
        }

        &.bg-size-auto {
            background-size: auto;
        }
    }

    .img-object {
        object-fit: cover;
    }

    .pointerC {
        cursor: pointer;
    }

    @import './max_widh';
    @import './buttons';
    @import './letter_style';

    @import './nuestras_empresas';
    @import './card_blog';
    @import './modal';
    @import './custom_checkbox';
    @import './tabs';
}