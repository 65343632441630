.card-blog {
    .card-body {
        background-color: $pale-grey;

        h4 {
            font-family: $font-family-archivo;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $dark-indigo;

            @media screen and (min-width: 992px) {
                font-size: 20px;
            }
        }
    }
}