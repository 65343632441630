.btn {
    &.btn-style-1,
    &.btn-style-2,
    &.btn-style-3 {
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        padding: 8px 20px 6px;
        letter-spacing: 0.47px;
        transition: all .4s ease-in-out;
        border-radius: 30px;
    }

    &.btn-style-1 {
        color: $greyish-brown;
        border: solid 1px $greyish-brown;
        background-color: $white;

        &:hover {
            color: $white;
            background-color: $dark-indigo;
        }
    }

    &.btn-style-2 {
        color: $white;
        background-color: $dark-indigo;
    }

    &.btn-style-3 {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;

        &:hover {
            color: $dark;
            background-color: $white;
        }
    }
}