@import '../variables';

.legal {
    &-informacion {
        padding: 50px 0;

        p {
            line-height: 1.65;
            letter-spacing: 0.57px;
            color: $greyish-brown;
        }

        a {
            color: $dark-indigo !important;
            text-decoration: underline !important;
        }

        @media screen and (min-width: 992px) {
            padding: 70px 0;
        }
    }
}