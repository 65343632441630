@import '../variables';

.home {
    background-color: $white;

    &-somos {
        padding: 40px 0;

        @media screen and (min-width: 992px) {
            padding: 80px 0;
        }

        .img-object {
            width: 100%;
            height: 280px;

            @media screen and (min-width: 992px) {
                height: 385px;
            }
        }
    }

    &-somos2 {
        padding: 40px 0;

        @media screen and (min-width: 992px) {
            padding: 100px 0;
        }

        .square {
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                width: 100%;
                height: 80%;
                background-color: $pale-grey;
                position: absolute;
                top: -40px;
                left: 0px;
                z-index: -1;

                @media screen and (min-width: 768px) {
                    width: 80%;
                    height: 100%;
                    top: -60px;
                    left: -40px;
                }
            }
        }

        .img-object {
            width: 100%;
            height: 360px;

            @media screen and (min-width: 992px) {
                height:450px;
            }
        }
    }

    &-informacion-3 {
        padding: 40px 0;
        background-color: $dark-indigo;
        position: relative;

        @media screen and (min-width: 992px) {
            padding: 60px 0;
        }

        &::after {
            content: "";
            width: 100%;
            height: 150px;
            background-color: $white;
            position: absolute;
            bottom: 0;
            left: 0;

            @media screen and (min-width: 768px) {
                height: 300px;
            }
        }

        .container-fluid {
            position: relative;
            z-index: 1;
        }

        p {
            max-width: 800px;
        }
    }

    &-ultimas-noticias {
        padding: 20px 0 40px;

        @media screen and (min-width: 992px) {
            padding: 30px 0 80px;
        }

        hr {
            width: 80px;
            height: 1px;
            margin: 13px 354px 44px 1px;
            background-color: $dark-indigo;
            border: solid 2px $dark-indigo;
        }
    }

    &-contactanos {
        padding: 40px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (min-width: 992px) {
            padding: 50px 0;
        }

        hr {
            width: 80px;
            height: 1px;
            border: solid 2px $white;
            background-color: $white;
        }

        .tlo {
            font-family: $font-family-archivo;
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $white;
            margin-bottom: 30px;

            @media screen and (min-width: 992px) {
                font-size: 20px;
            }
        }

        p {
            color: $white;
            font-size: 14px;
        }
    }
}