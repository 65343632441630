.tab-list { 
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }
}

.tab-list-item {
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark;
    position: relative;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 992px) {
        font-size: 18px;
    }
}

.tab-list-active {
    font-family: $font-family-archivo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-indigo;

    @media screen and (min-width: 992px) {
        font-size: 18px;
    }

    &::after {
        content: "";
        width: 80px;
        height: 3px;
        background-color: $dark-indigo;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}