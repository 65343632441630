.card-producto {
    max-width: 270px;
    border: none;
    border-radius: 0px;
    background-color: $pale-grey;

    .img-object {
        width: 100%;
        height: 280px;
        object-fit: contain;
        background-color: $white;
        border: solid 1px $pale-grey;
    }

    h4 {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;

        &.font-weight-bold {
            font-size: 22px
        }
    }
}