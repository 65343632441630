.modal {
    background: rgba(0, 0, 0, 0.6);

    .modal-dialog {
        @media screen and (min-width: 768px) {
            max-width: 590px;
        }
    }

    .modal-content {
        border-top: 11px solid $dark-indigo !important;
    }

    .close-modal {
        position: absolute;
        top: 15px;
        right: 25px;
        z-index: 1;
        font-size: 20px;
    }

    .modal-title {
        font-family: $font-family-archivo;
        font-size: 25px;
        letter-spacing: 0.39px;
        color: $black;
    }

    p {
        line-height: 1.75;
        letter-spacing: 0.53px;
        color: $greyish-brown;
    }

    form {
        .form-control {
            border: 1px solid #e4edf5;
            background-color: $pale-grey;
        }
    }
}